@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');
@font-face {
  font-family: 'VAG Rounded';
  src: url('./resources/fonts/VAGRounded-Black.woff2') format('woff2'),
      url('./resources/fonts/VAGRounded-Black.woff') format('woff');
  font-style: normal;
  font-display: swap;
}

*:focus {
  outline: none!important;
}

body {
  overflow-x: hidden;
  background-color: #f7f7f7;
  /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1200 800'%3E%3Cdefs%3E%3CradialGradient id='a' cx='0' cy='800' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23002846'/%3E%3Cstop offset='1' stop-color='%23002846' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='b' cx='1200' cy='800' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23003d61'/%3E%3Cstop offset='1' stop-color='%23003d61' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='c' cx='600' cy='0' r='600' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23003d61'/%3E%3Cstop offset='1' stop-color='%23003d61' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='d' cx='600' cy='800' r='600' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23002846'/%3E%3Cstop offset='1' stop-color='%23002846' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='e' cx='0' cy='0' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23002846'/%3E%3Cstop offset='1' stop-color='%23002846' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='f' cx='1200' cy='0' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%2300567C'/%3E%3Cstop offset='1' stop-color='%2300567C' stop-opacity='0'/%3E%3C/radialGradient%3E%3C/defs%3E%3Crect fill='url(%23a)' width='1200' height='800'/%3E%3Crect fill='url(%23b)' width='1200' height='800'/%3E%3Crect fill='url(%23c)' width='1200' height='800'/%3E%3Crect fill='url(%23d)' width='1200' height='800'/%3E%3Crect fill='url(%23e)' width='1200' height='800'/%3E%3Crect fill='url(%23f)' width='1200' height='800'/%3E%3C/svg%3E"); */
  background-attachment: fixed;
  background-size: cover;
  color: #002846;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
}

h1 {
  font-family: 'VAG Rounded';
}

.App {
  width: 100%;
  text-align: left;
}

.inactive {
  opacity: 0;
}

.active {
  animation-name: slideUp;
  animation-duration: 2s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  50% {
    opacity: .5s;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  
  }
}

.MuiRating-root {
  display: flex!important;
  flex-direction: row;
  justify-content: space-evenly;
  flex-grow: 1;
}

.MuiRating-root label span svg {
  font-size: 1.9rem;
}

.numerical_rating .MuiRating-icon svg {
  fill: #002846!important;
}



